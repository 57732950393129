
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NavigationLayout from "components/layout/NavigationLayout";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "components/Loading";
import { useRouter } from "next/router";
import { Axios, getLocalizedSSRAxios } from "api";
import * as Sentry from "@sentry/nextjs";
import { captureException, captureMessage } from "@sentry/nextjs";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import styles from "pages/palette/creator/[ccode].module.scss";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TitleBar from "components/layout/TitleBar";
import Image from "next/image";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Nickname from "components/Nickname";
import CreatorBadgeIcon from "images/icons/creator_badge.svg";
import { Col, Row } from "react-bootstrap";
import SimplePostListCard from "components/cards/SimplePostListCard";
import InstagramIcon from "images/icons/instagram_icon.svg";
import YoutubeIcon from "images/icons/youtube_icon.svg";
import TiktokIcon from "images/icons/tiktok_icon.svg";
import NaverBlogIcon from "images/icons/naver_blog_icon.svg";
import LinkTreeIcon from "images/icons/link_tree_icon.svg";
import { NewTopTabBar } from "components/TopTabBar";
import { productScrapContextRef } from "context/ProductScrapContext";
import ProductListCard from "components/cards/ProductListCard";
import { postScrapContextRef } from "context/PostScrapContext";
import ShareIcon from "images/icons/share.svg";
import ErrorIcon from "images/icons/error.svg";
import Head from "next/head";
import { generateSEOLink, getCreatorRoute, getInfluencerRoute, SITE_URL, storeRoute } from "common/const";
import UserContext from "context/AuthContext";
import FullButton from "components/buttons/FullButton";
import Link from "next/link";
import Modal from "components/Modal";
import { NicknameForm, ProfileImageEditor } from "pages/mypage/my-info";
import { useFormik } from "formik";
import * as yup from "yup";
import { KEY_NICKNAME } from "pages/auth/sign-up";
import { KEY_INSTAGRAM_LINK, KEY_INTRODUCTION, KEY_LINK_TREE_LINK, KEY_NAVER_BLOG_LINK, KEY_TIKTOK_LINK, KEY_YOUTUBE_LINK, SellerShopInfoForm } from "components/cards/MyInfoCard";
import { toast } from "react-toastify";
import PostListContext from "context/PostListContext";
import { addToSellerShop } from "api/addToSellerShop";
import DownloadIcon from "images/icons/download.svg";
import { handleCouponDownload } from "components/modal/MainCouponModal";
import { customAlert, formatDate, getExpiresAtDateFromDMY, numberWithComma } from "common/utils";
import { COUPON_TYPE_PERCENTAGE } from "components/cards/CheckoutCouponCard";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
import { setCountryCodeInCookieSSR } from "common/i18n";
import i18nContext from "context/I18nContext";
import ArrowBackIcon from "images/icons/arrow_back.svg";
import RoundCloseIcon from "images/icons/close_circle.svg";
import SearchIcon from "images/icons/search.svg";
import { AutoSizer, InfiniteLoader, List, WindowScroller } from "react-virtualized";
import CheckCircle from "images/icons/check_circle_2.svg";
import TruncateMarkup from "react-truncate-markup";
import InfiniteScroll from "react-infinite-scroll-component";
const KEY_STORE_TAB = 'store';
const KEY_PALETTE_TAB = 'palette';
const CHUNK_SIZE = 30;
const MAX_NUM_OF_OPTIONS = 15;
const READ_ITEM_SIZE = 20;
export const Error = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const { title, message, error } = props;
    return (<div className={styles.errorContainer}>
            {error &&
            <>
                    <ErrorIcon className={styles.errorIcon} viewBox="0 0 80 80"/>
                    <span className={styles.errorTitle}>{t('Error.loadingFailed')}</span>
                </>}
            <span className={styles.errorMessage}>{message}</span>
        </div>);
};
const ProductCard = ({ option, selected, onClick }) => {
    return (<div className={classNames(styles.productOptionRow, selected ? styles.productOptionRowSelected : undefined)} onClick={onClick}>
            <div className={styles.productOptionRowThumbnailWrapper}>
                <Image unoptimized layout="fill" src={option.image_url}/>
            </div>
            <div>
                {selected &&
            <CheckCircle viewBox="0 0 20 20" className={styles.productOptionRowCheckBox}/>}
                <span className={styles.productOptionRowBrandName}>{option.brand_name}</span>
                <TruncateMarkup lines={1} ellipsis={<span>...</span>}>
                    <span className={styles.productOptionRowProductName}>{option.product_name}</span>
                </TruncateMarkup>
            </div>
        </div>);
};
const AddProductModal = (props) => {
    const { t } = useTranslation('palette-new-index');
    const { isOpen, close, initialOptions, fetchStore, resetProductData } = props;
    const closeModal = () => {
        close();
    };
    const onBackClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CreatePostPageProductOptionTaggerBackClicked');
        close();
    };
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [cursor, setCursor] = useState(undefined);
    const searchKeyword = useRef('');
    const [selectedOptions, setSelectedOptions] = useState(initialOptions ? [...initialOptions] : []);
    const [error, setError] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const onSearch = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SellerShopPageOptionTagModalSearch', {
            keyword: inputValue
        });
        if (!inputValue)
            return;
        searchKeyword.current = inputValue;
        await setCursor(undefined);
        await setSearchResult([]);
        setError(false);
    };
    const onSelect = (option) => {
        const isSelected = Boolean(selectedOptions.filter(e => e.id === option.id)[0]);
        typeof mixpanel !== 'undefined' && mixpanel.track('SellerShopPageOptionTagModalSelectOption', {
            optionId: option.id,
            isSelected
        });
        if (selectedOptions.length > MAX_NUM_OF_OPTIONS - 1 && !isSelected) {
            alert(t('OptionTagModal.maxProducts'));
            return;
        }
        setSelectedOptions((val) => {
            const targetOptions = val.filter(e => e.id === option.id);
            if (targetOptions.length > 0) {
                return val.filter(e => e.id !== option.id);
            }
            else {
                return [...val, option];
            }
        });
    };
    const rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
        const option = searchResult[index];
        if (!option) {
            if (searchResult && index === searchResult.length) {
                return <div key={key} style={style} className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentCenter)}>
                    <Loading /></div>;
            }
            return <div key={key}></div>;
        }
        const selected = !!selectedOptions.filter(e => e.id === option.id)[0];
        return (<div key={key} style={style}>
                <ProductCard option={option} selected={selected} onClick={() => onSelect(option)}/>
            </div>);
    };
    const loadMoreRows = async ({ startIndex, stopIndex }) => {
        if (isLoading) {
            return;
        }
        try {
            if (!searchKeyword.current) {
                return;
            }
            setIsLoading(true);
            const res = await Axios.get('v1/my-shop/product-search', { timeout: 20000, params: {
                    cursor: cursor || undefined, query: searchKeyword.current, size: CHUNK_SIZE
                } });
            if (res.status < 400) {
                setCursor(res.data.cursor);
                if (res.data.products.length === 0) {
                    setError(true);
                }
                setSearchResult(val => {
                    return [...val].concat(res.data.products);
                });
            }
            else {
                setError(true);
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            setError(true);
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const remoteRowCount = !searchResult ? 0 : (cursor === null ? searchResult.length : searchResult.length + 1);
    const scrollRoot = useRef();
    const onProductOptionDelete = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SellerShopPageOptionTagModalDeleteButtonClicked');
        setCursor(undefined);
        searchKeyword.current = undefined;
        setSearchResult(undefined);
        setInputValue('');
        setIsFocused(false);
    };
    const onSubmit = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SellerShopPageOptionTagModalSubmitButtonClicked');
        try {
            setIsLoading(true);
            const res = await Axios.post('v1/my-shop/bulk-products/', {
                product_ids: selectedOptions.map(e => e.id)
            });
            if (res.status < 400) {
                setSelectedOptions([]);
                setInputValue('');
                setSearchResult([]);
                setCursor(null);
                resetProductData();
                fetchStore();
                toast.info(t('Toast.success'));
            }
            else {
                toast.info(res.data.display_message || t('Toast.Error'));
            }
        }
        catch (e) {
            captureException(e);
            alert(t('Toast.Error'));
        }
        finally {
            setIsLoading(false);
            closeModal();
        }
    };
    return (<Modal isOpen={isOpen} width={'100%'} height={'100%'} close={onBackClick} bodyRef={scrollRoot}>
            <div className={styles.tagModalSearchBarContainer}>
                <ArrowBackIcon className={styles.tagModalSearchBarBackButton} onClick={() => {
            closeModal();
        }}/>
                <div className={classNames(styles.tagModalSearchBarInputWrapper, isFocused ? styles.tagModalSearchBarInputWrapperFocused : undefined)}>
                    <form className={styles.tagModalSearchBarForm} action="." onSubmit={e => {
            e.preventDefault();
            onSearch();
        }}>
                        <input type="text" placeholder={t('OptionTagModal.searchPlaceholder')} className={classNames(styles.tagModalSearchBarInput, isFocused ? styles.tagModalSearchBarInputFocused : undefined)} value={inputValue} onChange={evt => setInputValue(evt.target.value)} onFocus={() => {
            setIsFocused(true);
        }} onBlur={() => {
            setIsFocused(false);
        }} autoFocus={true}/>
                        <input type={"submit"} style={{ display: "none" }}/>
                    </form>
                    {!!inputValue &&
            <RoundCloseIcon onClick={onProductOptionDelete} viewBox="0 0 16 16" className={styles.tagModalSearchBarResetIcon}/>}
                    <SearchIcon viewBox="0 0 24 24" className={classNames(styles.tagModalSearchIcon, isFocused ? styles.tagModalSearchIconFocused : undefined)} onClick={onSearch}/>
                </div>
            </div>
            {selectedOptions && selectedOptions.length > 0 &&
            <div className={styles.productOptionTaggerPreviewContainer}>
                    {selectedOptions.map(option => {
                    return (<div key={option.id} className={styles.productOptionTaggerPreviewWrapper}>
                                    <RoundCloseIcon onClick={() => {
                            setSelectedOptions(val => {
                                return [...val].filter(e => e.id !== option.id);
                            });
                        }} viewBox="0 0 16 16" className={styles.productOptionTaggerPreviewRemove}/>
                                    <div className={styles.productOptionTaggerPreviewImageWrapper}>
                                        <Image unoptimized layout="fill" src={option.image_url}/>
                                    </div>
                                </div>);
                })}
                </div>}
            <div className={classNames(utilStyles.sidePadding)} style={{ marginTop: 12 }}>
                {searchResult && searchResult.length === 0 && error ?
            <div className={styles.noOptionFound}>
                            {t('OptionTagModal.noProductFound')}
                        </div>
            :
                <InfiniteLoader isRowLoaded={({ index }) => {
                        return !!searchResult[index];
                    }} loadMoreRows={loadMoreRows} rowCount={remoteRowCount} minimumBatchSize={CHUNK_SIZE}>
                            {({ onRowsRendered }) => (<WindowScroller scrollElement={scrollRoot.current}>
                                    {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (<AutoSizer disableHeight>
                                            {({ width }) => (<div ref={el => registerChild(el)}>
                                                    <List overscanRowCount={5} autoHeight height={height} isScrolling={isScrolling} width={width} scrollTop={scrollTop} onScroll={onChildScroll} onRowsRendered={onRowsRendered} estimatedRowSize={88} ref={registerChild} rowCount={remoteRowCount} rowHeight={88} rowRenderer={rowRenderer}/>
                                                </div>)}
                                        </AutoSizer>)}
                                </WindowScroller>)}
                        </InfiniteLoader>}
            </div>
            <div className={styles.pseudoProductOptionSubmitButtonWrapper}/>
            <div className={styles.productOptionSubmitButtonWrapper}>
                <FullButton disabled={isLoading || selectedOptions.length === 0} height={48} fontSize={16} onClick={onSubmit}>
                    {t('OptionTagModal.productSelectionComplete')}
                </FullButton>
            </div>
        </Modal>);
};
const ProfileEditModal = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const { isOpen, creator, setCreator } = props;
    const close = props.close || function () { };
    const closeModal = () => {
        close();
    };
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: Object.assign({}, NicknameForm.initialValue, {
            [KEY_NICKNAME]: creator.nickname
        }, SellerShopInfoForm.initialValues, {
            [KEY_INTRODUCTION]: creator.introduction || '',
            [KEY_INSTAGRAM_LINK]: creator.instagram_link || '',
            [KEY_YOUTUBE_LINK]: creator.youtube_link || '',
            [KEY_TIKTOK_LINK]: creator.tiktok_link || '',
            [KEY_NAVER_BLOG_LINK]: creator.naver_blog_link || '',
            [KEY_LINK_TREE_LINK]: creator.link_tree_link || ''
        }),
        validationSchema: yup.object(Object.assign({}, NicknameForm.validationSchema, SellerShopInfoForm.validationSchema)),
        onSubmit: async (values) => {
            if (!NicknameForm.preSubmit(values, () => { })) {
                return;
            }
            if (!SellerShopInfoForm.preSubmit(values)) {
                return;
            }
            try {
                setIsLoading(true);
                const data = JSON.parse(JSON.stringify(values));
                const res = await Axios.patch('v1/my-shop/', data);
                if (res.status < 400) {
                    if (user) {
                        userContext.setUser(oldUser => {
                            const newUser = Object.assign({}, oldUser);
                            newUser.nickname = res.data.nickname;
                            return newUser;
                        });
                    }
                    setCreator(res.data);
                    closeModal();
                    toast.info(t('ProfileEditModal.editSuccess'));
                }
                else {
                    alert(res.data.display_message || t('ProfileEditModal.temporaryError'));
                }
            }
            catch (e) {
                captureException(e);
                alert(t('ProfileEditModal.temporaryError'));
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const scrollRef = useRef(null);
    const { handleSubmit, resetForm } = formik;
    useEffect(() => {
        if (!isOpen) {
            resetForm();
            if (scrollRef.current) {
                scrollRef.current.scrollTo(0, 0);
            }
        }
    }, [isOpen]);
    return (<Modal isOpen={isOpen} close={closeModal} width={'100%'} height={'100%'}>
            <div ref={scrollRef} className={styles.modalScrollContainer}>
                <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar isClose close={closeModal}>{t('ProfileEditModal.title')}</TitleBar>
                </div>
                <div className={utilStyles.sidePadding}>
                    <div style={{ height: 24 }}/>
                    <ProfileImageEditor horizontal onChange={e => {
            const newCreator = JSON.parse(JSON.stringify(creator));
            newCreator.profile_image_url = e;
            setCreator(newCreator);
        }}/>
                    <div style={{ height: 40 }}/>
                    {/*<span className={styles.profileEditLabel}>{t('ProfileEditModal.link')}</span>*/}
                    {/*<div style={{marginBottom: 24}}>*/}
                    {/*    <TextInput ellipsis dark readonly={true} value={`${SITE_URL}/s/${creator.code}`}></TextInput>*/}
                    {/*    <div style={{height: 12}} />*/}
                    {/*    <TextInput allowEmptyThrottling readonly={isLoading} value={creator.code} disabled />*/}
                    {/*    <span className={styles.profileEditWarn}>{t('ProfileEditModal.linkWarning')}</span>*/}
                    {/*</div>*/}
                    <span className={styles.profileEditLabel}>{t('ProfileEditModal.username')}</span>
                    {isOpen &&
            <NicknameForm formik={formik} isLoading={isLoading}/>}
                    <SellerShopInfoForm formik={formik} isLoading={isLoading}/>
                    <div className={styles.profileEditSubmitButtonContainer}>
                        <FullButton disabled={isLoading} title={t('ProfileEditModal.saveProfile')} fontSize={16} height={48} onClick={handleSubmit}/>
                    </div>
                </div>
            </div>
        </Modal>);
};
const CreatorPage = (props) => {
    const { t } = useTranslation('palette-creator-ccode');
    const mainCouponModal = useTranslation('components-modal-MainCouponModal');
    const _creator = props.creator;
    const _error = props.ssrError;
    const _isInfluencerPage = props.creator?.is_influencer;
    const [data, setData] = useState({
        isLoading: false,
        creator: _creator,
        error: _error
    });
    const user = useContext(UserContext).user;
    const { reportedPostIds } = useContext(PostListContext);
    const { countryCode } = useContext(i18nContext);
    const [cursorInfo, setCursorInfo] = useState({
        cursor: null,
        hasNext: false
    });
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [storeData, setStoreData] = useState({
        products: null,
        error: false
    });
    const [paletteData, setPaletteData] = useState({
        posts: null,
        error: false
    });
    const router = useRouter();
    useEffect(() => {
        if (!router.isReady)
            return;
        if (!router.query.tab) {
            const creator = data.creator;
            router.replace({
                query: Object.assign({}, router.query, {
                    tab: ((creator?.is_seller || creator?.is_influencer) ?
                        ((!(creator?.has_seller_shop_products) && creator?.has_post) ? KEY_PALETTE_TAB : KEY_STORE_TAB) :
                        KEY_PALETTE_TAB)
                })
            }, undefined, {
                shallow: true,
                scroll: false
            });
        }
    }, [router.isReady, router.query]);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const creator = data.creator;
    const isMySellerShop = creator && user && creator.nickname === user.nickname;
    const fetchStore = async () => {
        try {
            const res = await Axios.get(`v1/seller-shop/${data.creator.code}/products/`, {
                params: {
                    cursor: cursorInfo.cursor,
                    size: READ_ITEM_SIZE
                }
            });
            if (res.status < 400) {
                setStoreData(values => {
                    const newValues = JSON.parse(JSON.stringify(values));
                    const prevProducts = values.products || [];
                    const newProducts = res.data.products || [];
                    newValues.products = [...prevProducts, ...newProducts];
                    console.log('zzzzz', newValues);
                    return newValues;
                });
                setCursorInfo({
                    cursor: res.data.cursor,
                    hasNext: res.data.has_next
                });
                productScrapContextRef.current.updateByProductIdsAndScrappedProductIds(res.data.products.map(e => e.id), res.data['scrapped_pids']);
            }
            else {
                setStoreData(values => {
                    const newValues = Object.assign({}, values);
                    newValues.error = res.data.display_message || t('CreatorPage.loadingError');
                    return newValues;
                });
            }
        }
        catch (e) {
            setStoreData(values => {
                const newValues = Object.assign({}, values);
                newValues.error = t('CreatorPage.loadingError');
                return newValues;
            });
        }
    };
    const fetchPalette = async () => {
        try {
            const res = await Axios.get(`v1/palette/creator/${data.creator.code}/palette/`, {
                params: {
                    cursor: cursorInfo.cursor,
                    size: READ_ITEM_SIZE
                }
            });
            if (res.status < 400) {
                setPaletteData(values => {
                    const newValues = JSON.parse(JSON.stringify(values));
                    const prevPosts = values.posts || [];
                    const posts = res.data.posts || [];
                    newValues.posts = [...prevPosts, ...posts];
                    return newValues;
                });
                setCursorInfo({
                    cursor: res.data.cursor,
                    hasNext: res.data.has_next
                });
                postScrapContextRef.current.updateByPostIdsAndScrappedPostIds(res.data.posts.map(e => e.id), res.data['scrapped_pids']);
            }
            else {
                setPaletteData(values => {
                    const newValues = Object.assign({}, values);
                    newValues.error = res.data.display_message || t('CreatorPage.loadingError');
                    return newValues;
                });
            }
        }
        catch (e) {
            setPaletteData(values => {
                const newValues = Object.assign({}, values);
                newValues.error = t('CreatorPage.loadingError');
                return newValues;
            });
        }
    };
    useEffect(async () => {
        const code = data.creator?.code;
        if (!code)
            return;
        const tab = router.query.tab;
        if (tab === KEY_STORE_TAB) {
            if (data.creator.is_seller || data.creator.is_influencer) {
                fetchStore();
            }
        }
        else {
            fetchPalette();
        }
        typeof mixpanel !== 'undefined' && mixpanel.track('ViewSellerShop', { ccode: code, tab: tab });
    }, [data.creator?.code, router.query.tab]);
    const tabs = ((data.creator?.is_seller || data.creator?.is_influencer) ? [
        {
            route: KEY_STORE_TAB,
            title: t('CreatorPage.storeTab'),
            onClick: () => {
                setCursorInfo({
                    cursor: null,
                    hasNext: false
                });
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_STORE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        },
        data.creator?.has_post ? {
            route: KEY_PALETTE_TAB,
            title: t('CreatorPage.paletteTab'),
            onClick: () => {
                setCursorInfo({
                    cursor: null,
                    hasNext: false
                });
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_PALETTE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        } : null,
    ] : [
        {
            route: KEY_PALETTE_TAB,
            title: t('CreatorPage.paletteTab'),
            onClick: () => {
                ssetCursorInfo({
                    cursor: null,
                    hasNext: false
                });
                router.replace({ query: Object.assign({}, router.query, { tab: KEY_PALETTE_TAB }) }, undefined, {
                    shallow: true,
                    scroll: false
                });
            }
        },
    ]).filter(e => !!e);
    const creatorUrl = creator ? `${SITE_URL}/s/${creator.code}` : '';
    const metaCreatorUrl = creator ? `/s/${creator.code}` : '';
    const onEditProfileClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CreatorPageEditProfileButtonClick');
        setIsEditModalOpen(true);
    };
    const onAddProductClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CreatorPageAddProductButtonClick');
        setIsAddProductModalOpen(true);
    };
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    const [deletedProducts, setDeletedProducts] = useState([]);
    const [isCouponLoading, setIsCouponLoading] = useState(false);
    const handleDeleteButtonClick = async (productId) => {
        if (isDeleteLoading) {
            return;
        }
        setIsDeleteLoading(true);
        await addToSellerShop(productId, deletedProducts.includes(productId), t);
        setDeletedProducts(prevState => {
            if (prevState.includes(productId)) {
                return prevState.filter(id => id !== productId);
            }
            else {
                return [...prevState, productId];
            }
        });
        setIsDeleteLoading(false);
    };
    const handleShareProductButtonClick = (product_id) => {
        if (creator.code && product_id) {
            if (_isInfluencerPage) {
                navigator.clipboard.writeText(`${SITE_URL}${getInfluencerRoute(creator.code)}/${product_id}`);
            }
            else {
                navigator.clipboard.writeText(`${SITE_URL}${getCreatorRoute(creator.code)}/${product_id}`);
            }
            toast.info(t('Toast.copySuccess'));
        }
        else {
            toast.info(t('Toast.copyFail'));
        }
    };
    const handleShareButtonClick = () => {
        if (_isInfluencerPage) {
            navigator.clipboard.writeText(`${SITE_URL}${getInfluencerRoute(creator.code)}`);
        }
        else {
            navigator.clipboard.writeText(`${SITE_URL}${getCreatorRoute(creator.code)}`);
        }
        toast.info(t('Toast.copySuccess'));
    };
    const customTabs = [
        (<button className={utilStyles.transparentButton} onClick={handleShareButtonClick}>
                <ShareIcon viewBox="0 0 24 24" className={utilStyles.cursorPointer} width={25} height={25}/>
            </button>),
    ];
    const resetProductData = () => {
        setCursorInfo({
            cursor: null,
            hasNext: false
        });
        setStoreData({
            products: null,
            error: false
        });
    };
    return (<>
            <Head>
                {creator &&
            <>
                        <title>{t('CreatorPage.title', { nickname: creator.nickname })}</title>
                    <meta name="description" content={creator.introduction || ''} key="description"/>
                    <meta property="og:site_name" content={t('CreatorPage.siteName')} key="ogSiteName"/>
                    <meta property="og:title" content={t('CreatorPage.ogTitle', { nickname: creator.nickname })} key="ogTitle"/>
                    <meta property="og:description" content={creator.introduction || ''} key="ogDescription"/>
                    <meta property="og:image" content="https://d1cnx04b8cgzcv.cloudfront.net/static/hemeko_logo_v2.png" key="ogImage"/>
                    <meta property="og:url" content={creatorUrl} key="ogUrl"/>
                    {generateSEOLink(metaCreatorUrl, router.locale)}
                </>}
        </Head>
        <div className={classNames(utilStyles.pageContainer, styles.container)}>
            {creator && user && creator.nickname === user.nickname &&
            <>
                    <ProfileEditModal setCreator={c => {
                    setData(values => {
                        const newValues = JSON.parse(JSON.stringify(values));
                        newValues.creator = c;
                        return newValues;
                    });
                }} creator={creator} isOpen={isEditModalOpen} close={() => setIsEditModalOpen(false)}/>
                    <AddProductModal setStoreData={setStoreData} fetchStore={fetchStore} resetProductData={resetProductData} creator={creator} isOpen={isAddProductModalOpen} close={() => setIsAddProductModalOpen(false)}/>
                </>}
            {data.isLoading ?
            <Loading />
            :
                !!creator ?
                    <>
                            <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                                <TitleBar shareLink={creatorUrl} close={() => router.back()} kakaoShareCallback={() => {
                            try {
                                Kakao.Share.sendScrap({
                                    requestUrl: creatorUrl
                                });
                            }
                            catch (e) {
                                Sentry.captureException(e);
                            }
                        }} isBack={true} customTabs={customTabs}>
                                    <Nickname value={creator.nickname.toUpperCase()} creatorCode={creator.code}/>
                                </TitleBar>
                            </div>
                            <div className={styles.creatorSection}>
                                <div className={classNames(utilStyles.flexCol, utilStyles.alignItemsCenter)}>
                                    {!!creator.profile_image_url
                            ?
                                <div className={styles.profileImageWrapper}>
                                                <Image unoptimized src={creator.profile_image_url} objectFit="cover" layout="fill"/>
                                            </div>
                            :
                                <DefaultProfileIcon viewBox="0 0 32 32" className={styles.profileImageWrapper}/>}
                                    <div style={{ marginTop: 16 }}>
                                        <Nickname value={creator.nickname} creatorCode={creator.code} className={classNames(styles.contentNickname, creator.is_verified ? utilStyles.verticalMiddle : undefined)}/>
                                        {creator.is_verified &&
                            <CreatorBadgeIcon viewBox="0 0 16 16" className={classNames(utilStyles.verticalMiddle, styles.badge)}/>}
                                    </div>
                                    {creator.introduction &&
                            <div className={styles.introduction}>{creator.introduction}</div>}
                                    {(creator.instagram_link || creator.youtube_link || creator.tiktok_link || creator.naver_blog_link) &&
                            <div className={styles.snsSection}>
                                            {creator.instagram_link &&
                                    <a href={creator.instagram_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <InstagramIcon viewBox="0 0 32 32" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.youtube_link &&
                                    <a href={creator.youtube_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <YoutubeIcon viewBox="0 0 32 32" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.tiktok_link &&
                                    <a href={creator.tiktok_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <TiktokIcon viewBox="0 0 32 32" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.naver_blog_link &&
                                    <a href={creator.naver_blog_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <NaverBlogIcon viewBox="0 0 32 32" className={styles.snsIcon}/>
                                                </a>}
                                            {creator.link_tree_link &&
                                    <a href={creator.link_tree_link} target="_blank" className={styles.snsIconWrapper}>
                                                    <LinkTreeIcon viewBox="0 0 32 32" className={styles.snsIcon}/>
                                                </a>}
                                        </div>}
                                    {isMySellerShop &&
                            <div className={styles.buttonContainer}>
                                            <div className={styles.profileEditButton} onClick={onEditProfileClick}>
                                                {t('CreatorPage.profileEdit')}
                                            </div>
                                            <div className={styles.addProductButtonWrapper} onClick={onAddProductClick}>
                                                {t('CreatorPage.addProduct')}
                                            </div>
                                        </div>}
                                    {!!creator?.molds && creator.molds.slice(0, 1).map((mold) => {
                            return (<div key={`mold-${mold.id}`} className={styles.couponContainer} onClick={() => {
                                    handleCouponDownload(router, mold, false, isCouponLoading, setIsCouponLoading, () => {
                                        typeof mixpanel !== 'undefined' && mixpanel.track('InfluencerCouponDownloadSuccess', {
                                            moldId: mold.id,
                                            code: creator.code
                                        });
                                        fetchStore();
                                        customAlert(t('CreatorPage.couponIssuedAlert'), t('CreatorPage.confirm'), () => { });
                                    }, mainCouponModal.t);
                                }}>
                                                    <div className={styles.couponContentContainer}>
                                                        <div className={styles.couponInfo}>
                                                            {mold.type === COUPON_TYPE_PERCENTAGE ? `${numberWithComma(mold.amount)}%` : t('CreatorPage.currency', { amount: numberWithComma(mold.amount) })} | {mold.name}
                                                        </div>
                                                        {<div className={styles.couponExpire}>
                                                                {mold.expiration_type === 'fixed_date' ?
                                        t('CreatorPage.couponExpire', { duration: formatDate(mold.expires_at) }) :
                                        t('CreatorPage.couponExpireAfter', { duration: getExpiresAtDateFromDMY(mold.valid_day, mold.valid_month, mold.valid_year, countryCode) })}
                                                            </div>}
                                                    </div>
                                                    <div className={styles.couponButtonContainer}>
                                                        <DownloadIcon viewBox="0 0 19 18" width={24} height={24}/>
                                                    </div>
                                                    <div className={styles.couponCutter}>
                                                        <div /><div /><div /><div /><div /><div />
                                                    </div>
                                                </div>);
                        })}
                                </div>
                            </div>
                            {isMobile && <div className={utilStyles.mobileBorder} style={{ marginTop: 24 }}/>}
                            <div className={styles.contentSection}>
                                <NewTopTabBar activeRoute={router.query.tab} smallText tabs={tabs}/>
                                {(router.query.tab === KEY_STORE_TAB && (data.creator?.is_seller || data.creator?.is_influencer)) ?
                            ((storeData.products?.length > 0) ?
                                <div>
                                                    {storeData.products && storeData.products.length > 0 &&
                                        <div className={styles.postProductSection}>
                                                            <div className={styles.productsWrapper}>
                                                                <InfiniteScroll scrollThreshold={'80%'} hasMore={cursorInfo.hasNext} dataLength={storeData.products?.length} next={fetchStore} style={{ overflow: 'hidden' }} loader={<div></div>}>
                                                                    <Row>
                                                                        {storeData.products.map((e) => {
                                                return (<Col key={e.id} xs={isLarge ? 3 : 6} className={styles.col}>
                                                                                        <div className={styles.storeCardWrapper}>
                                                                                            <ProductListCard creatorCode={creator.code} product={e} referralInfo={[creator.referral_code, null]} onClick={() => { mixpanel.track('SelectProductOnSellerShop', { ccode: creator.referral_code, product_id: e.id }); }} textPaddingType="medium" isInfluencer={_isInfluencerPage}/>
                                                                                            {isMySellerShop && (<div className={styles.deleteButtonContainer}>
                                                                                                        <div onClick={() => handleDeleteButtonClick(e.id)} className={styles.deleteButton}>
                                                                                                            {!deletedProducts.includes(e.id) ? t('CreatorPage.deleteFromShop') : t('CreatorPage.addToMyShop')}
                                                                                                        </div>
                                                                                                        <div className={styles.shareButton} onClick={() => {
                                                            handleShareProductButtonClick(e.id);
                                                        }}>
                                                                                                            <ShareIcon viewBox="0 0 24 24" className={utilStyles.cursorPointer} width={15} height={15}/>
                                                                                                        </div>
                                                                                                    </div>)}
                                                                                        </div>
                                                                                    </Col>);
                                            })}
                                                                    </Row>
                                                                </InfiniteScroll>
                                                            </div>
                                                        </div>}
                                                </div>
                                :
                                    <>
                                                    {!storeData.error && user?.code && user?.code === data.creator.code ?
                                            <div className={classNames(utilStyles.sidePadding, utilStyles.alignItemsCenter, utilStyles.flexCol)}>
                                                                <span className={styles.addText} style={{ marginTop: 40 }}>{t('CreatorPage.noProducts')}</span>
                                                                <span className={styles.addText} style={{ marginTop: 6, marginBottom: 24 }}>
                                                                    <Trans i18nKey="palette-creator-ccode:CreatorPage.addProductsGuide" components={[<span className={classNames(utilStyles.brandColorText, utilStyles.bold)}/>]}/>
                                                                </span>
                                                                <Link href={storeRoute}>
                                                                    <a className={utilStyles.fullWidth} onClick={() => { mixpanel.track('GoToStoreForSelection'); }}>
                                                                        <FullButton title={t('CreatorPage.goToStore')} fontSize={16} height={44}/>
                                                                    </a>
                                                                </Link>
                                                            </div>
                                            :
                                                <Error error={!!storeData.error} message={storeData.error || t('CreatorPage.noRegisteredProducts')}/>}
                                                </>)
                            :
                                (paletteData.posts && paletteData.posts.length !== 0 ?
                                    <div className={styles.paletteWrapper}>
                                                    <InfiniteScroll scrollThreshold={'80%'} hasMore={cursorInfo.hasNext} dataLength={paletteData.posts?.length} next={fetchPalette} style={{ overflow: 'hidden' }} loader={<div></div>}>
                                                        <Row>
                                                            {paletteData.posts.map((e) => {
                                            return (<Col key={e.id} xs={isLarge ? 3 : 6}>
                                                                            <div className={styles.paletteCardWrapper}>
                                                                                <SimplePostListCard post={e}/>
                                                                            </div>
                                                                        </Col>);
                                        })}
                                                        </Row>
                                                    </InfiniteScroll>
                                                </div>
                                    :
                                        <Error error={!!paletteData.error} message={paletteData.error || t('CreatorPage.noRegisteredPosts')}/>)}
                            </div>
                        </>
                    :
                        <div></div>}
        </div>
        </>);
};
CreatorPage.getLayout = NavigationLayout;
const MESSAGE_KEY_MAP = {
    "US": {
        "CreatorPage.loadingError": "Failed to load the page. Please try again later."
    },
    "KR": {
        "CreatorPage.loadingError": "페이지를 불러오지 못했습니다. 잠시 후 다시 시도해주세요."
    }
};
async function getServerSideProps(context) {
    const { countryCode } = setCountryCodeInCookieSSR(context);
    let creator = null;
    let error = null;
    const creatorCode = context.params.ccode;
    try {
        const axios = getLocalizedSSRAxios(context);
        const res = await axios.get(`/v1/palette/creator/${creatorCode}/`);
        if (res.status < 400) {
            creator = res.data;
        }
        else {
            error = res.data.display_message || MESSAGE_KEY_MAP[countryCode]["CreatorPage.loadingError"];
            if (res.status !== 404) {
                captureMessage(JSON.stringify(res.data));
            }
        }
    }
    catch (e) {
        captureException(e);
        error = MESSAGE_KEY_MAP[countryCode]["CreatorPage.loadingError"];
    }
    return {
        props: {
            creator: creator,
            ssrError: error,
            countryCode
        }
    };
}
export default CreatorPage;

    async function __Next_Translate__getServerSideProps__195d0ead1d0__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/palette/creator/[ccode]',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195d0ead1d0__ as getServerSideProps }
  